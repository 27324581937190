import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  FormControlLabel,
  Tooltip,
  Switch as MuiSwitch,
  Select,
  MenuItem,
  Chip,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Alert, AlertTitle } from '@material-ui/lab';
import Link from 'components/Link';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import {
  Avatar,
  Button,
  Caption,
  Card,
  IconButton,
  Paragraph,
} from 'react-native-paper';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import ErgIcon from 'shared/components/ErgIcon';
import FeatureIcon from 'shared/components/FeatureIcon';
import FeaturesSelection from 'shared/components/TrackEdit/FeaturesSelection';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import { colors, sizes } from 'shared/styles';
import {
  AdminTrackAction,
  ErgType,
  Track,
  TrackFeature,
  TrackMode,
  TrackType,
  User,
  UserDisplay,
} from 'shared/types';
import styled from 'utils/styled';
import CsvErgWodImporter from './CsvErgWodImporter';
import CsvResultsImporter from './CsvResultsImporter';
import EditableText from './EditableText';
import PendingResultsModal from './PendingResultsModal';
import ImageUploadModal from '../../components/ImageUploadModal';
import UsersTable from './UsersTable';
import WorkoutsTable from './WorkoutsTable';
import { COMP_URL, SERVER_URL } from 'utils/config';
import useSnackBar from 'shared/hooks/useSnackBar';

const ADMIN_TRACK_FRAGMENT = gql`
  fragment AdminTrackFragment on Track {
    id
    ownerId
    name
    description
    membershipDescription
    trialDescription
    registrationDescription
    promoMessage
    webPath(url: true)
    features
    imageUrl
    featuredImage
    workoutStats
    discoverable
    autoJoin
    userDisplay
    archivedAt
  }
`;
const GET_TRACKS = gql`
  query GetTrack($id: ID!) {
    track(id: $id) {
      ...AdminTrackFragment
      idToken
      name
      type
      insertedAt
      usersCount(membershipStatuses: ["active"])
      workoutsCount
      trackMode
      planWeeks
      adminActions
      compStatus
      payoutAccess
      bikeCount: workoutsCount(ergType: "bike")
      skiCount: workoutsCount(ergType: "ski")
      rowCount: workoutsCount(ergType: "row")
      fitCount: workoutsCount(ergType: "fit")
      multiCount: workoutsCount(ergType: "multi")
      slug
      source {
        id
        name
        slug
      }
      admins {
        id
        name
      }
      activePrice {
        trialDays
      }
      prices {
        name
        token
        displayText
      }
    }
  }
  ${ADMIN_TRACK_FRAGMENT}
`;

const TRACK_UPDATE_MUTATION = gql`
  mutation UpdateTrack($track: TrackInput!) {
    trackUpdate(track: $track) {
      ...AdminTrackFragment
    }
  }
  ${ADMIN_TRACK_FRAGMENT}
`;

const TOGGLE_ARCHIVE_MUTATION = gql`
  mutation ToggleTrackArchive($id: ID!) {
    trackArchiveToggle(id: $id) {
      id
      archivedAt
    }
  }
`;

const CREATE_EXPRESS_DASHBOARD_SESSION = gql`
  mutation CreateExpressDashboardSession($trackId: ID!) {
    url: trackExpressDashboardCreate(trackId: $trackId)
  }
`;

const VerticalTable = styled.view({});

const Row = styled.view({
  flexDirection: 'row',
  borderBottomColor: colors.dividerColor,
  borderBottomWidth: sizes.pixel,
  padding: 10,
});

const HeaderCell = styled.view({
  display: 'flex',
  margin: 10,
  padding: 1,
  flexDirection: 'row',
  flex: 0.8,
});

const ContentCell = styled.view({
  borderBottomColor: '#ccc',
  marginBottom: '10px solid #ccc',
  display: 'flex',
  margin: 10,
  padding: 1,
  flexDirection: 'row',
  flex: 0.2,
});

const iconProps = {
  size: 18,
  color: 'black',
  padding: 10,
};

const styles = StyleSheet.create({
  boldText: {
    fontWeight: 'bold',
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
  },
});

interface Props {}

interface AdminTrack extends Track {
  idToken: string;
  archivedAt?: string;
  ownerId: string;
  admins: User[];
  adminActions: AdminTrackAction[];
  featureImage: string;
  insertedAt: Date;
  bikeCount: number;
  rowCount: number;
  skiCount: number;
  promoMessage: string;
  fitCount: number;
  multiCount: number;
  trialDescription: string;
  membershipDescription: string;
  workoutsCount: number;
  payoutAccess: boolean;
  registrationDescription: string;
}

interface QueryData {
  track: AdminTrack;
}

interface Params {
  trackId: string;
  tab: string;
}

const ActionsContainer = styled.view({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  margin: 10,
});

const TrackShow: React.FC<Props> = () => {
  const { trackId, tab } = useParams<Params>();
  const history = useHistory();
  const confirmWith = useConfirmModal();
  const showSnackBar = useSnackBar();
  const [createExpressSession, { loading: expressLoading }] = useMutation(
    CREATE_EXPRESS_DASHBOARD_SESSION
  );

  const [showFeaturesSelection, setShowFeaturesSelection] = useState(false);
  const [showLogoUpload, setShowLogoUpload] = useState(false);
  const [showBannerUpload, setShowBannerUpload] = useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const { currentUser } = useCurrentUser();
  const { data } = useQuery<QueryData>(GET_TRACKS, {
    variables: {
      id: trackId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const track = data && data.track;

  const activePrice = track && track.activePrice;
  const trialPrice = activePrice && activePrice.trialDays;

  const [updateTrackMutation] = useMutation(TRACK_UPDATE_MUTATION);
  const [toggleArchiveMutation] = useMutation(TOGGLE_ARCHIVE_MUTATION);

  const updateTrack = (data: any) =>
    updateTrackMutation(data).catch(({ graphQLErrors }) => {
      const message =
        graphQLErrors && graphQLErrors.map((e: any) => e.message).join(', ');
      confirmWith({
        title: 'Error',
        message: message || 'Error saving information. Please, try again!',
        hideCancel: true,
      });
    });

  if (!track) return null;

  const isSingleUser = track.trackMode === TrackMode.SingleUser;
  const showUserTab =
    !isSingleUser &&
    (track.type === TrackType.Private || currentUser.superadmin);

  if (!track.adminActions.includes(AdminTrackAction.Edit)) {
    return (
      <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle>
        The owner of this group has no active billing plan.
      </Alert>
    );
  }

  return (
    <View>
      <Card>
        <Card.Title
          title={track.name}
          subtitle={track.source.name}
          left={() => (
            <Avatar.Image size={48} source={{ uri: track.imageUrl }} />
          )}
          right={
            track.archivedAt
              ? () => <Chip style={{ marginRight: 10 }} label="Archived" />
              : undefined
          }
        />
        <Card.Content>
          {track.admins.length > 0 && (
            <Paragraph>
              <Tooltip title="Admin Users">
                <span>
                  <IconButton icon="shield-account" size={18}></IconButton>
                </span>
              </Tooltip>
              {track.admins.map((a: any) => (
                <Link
                  style={{ marginRight: 10 }}
                  key={a.id}
                  to={`/users/${a.id}/results`}
                >
                  {a.name}
                  {a.id === track.ownerId && (
                    <Tooltip title="Group Owner">
                      <MaterialCommunityIcon
                        name="crown"
                        color={colors.primaryColor}
                        size={16}
                      />
                    </Tooltip>
                  )}
                </Link>
              ))}
            </Paragraph>
          )}
          <Paragraph>
            <Tooltip title="Creation Date">
              <span>
                <IconButton icon="calendar" size={18}></IconButton>
              </span>
            </Tooltip>
            {new Date(track.insertedAt).toLocaleDateString()}
          </Paragraph>
          <Paragraph>
            <ErgIcon
              ergType={ErgType.bike}
              size={16}
              color="black"
              style={{ marginLeft: 13, marginRight: 8 }}
            ></ErgIcon>
            {track.bikeCount ? track.bikeCount : '0'}
            <ErgIcon
              ergType={ErgType.row}
              size={16}
              color="black"
              style={{ marginLeft: 8, marginRight: 8 }}
            ></ErgIcon>
            {track.rowCount ? track.rowCount : '0'}
            <ErgIcon
              ergType={ErgType.ski}
              size={16}
              color="black"
              style={{ marginLeft: 8, marginRight: 8 }}
            ></ErgIcon>
            {track.skiCount ? track.skiCount : '0'}
            {!!track.multiCount && (
              <>
                <ErgIcon
                  ergType={ErgType.multi}
                  size={16}
                  color="black"
                  style={{ marginLeft: 8, marginRight: 8 }}
                ></ErgIcon>
                {track.multiCount}
              </>
            )}
            {track.features.includes(TrackFeature.Fit) && (
              <>
                <ErgIcon
                  ergType={ErgType.fit}
                  size={16}
                  color="black"
                  style={{ marginLeft: 8, marginRight: 8 }}
                ></ErgIcon>
                {track.fitCount ? track.fitCount : '0'}
              </>
            )}
          </Paragraph>

          <View style={styles.buttonContainer}>
            <Button
              style={{ marginRight: 10 }}
              icon="chart-bar"
              mode="outlined"
              color={colors.primaryColor}
              onPress={() => {
                history.push(`/track-stats/${track.id}`);
              }}
            >
              Stats
            </Button>
            {!!track.compStatus && (
              <>
                <Button
                  style={{ marginRight: 10 }}
                  icon="podium"
                  mode="outlined"
                  color={colors.leaderboardColor}
                  onPress={() => {
                    window.location.href = `${COMP_URL}/leaderboard/${
                      track.idToken
                    }${track.compStatus === 'local' ? '?local=true' : ''}`;
                  }}
                >
                  Leaderboard
                </Button>
                <Button
                  style={{ marginRight: 10 }}
                  icon="shield-account"
                  mode="outlined"
                  onPress={() => {
                    window.location.href = `${SERVER_URL}/leaderboard/${track.idToken}/registrations`;
                  }}
                >
                  Comp Registrations
                </Button>
                <PendingResultsModal track={track} />
              </>
            )}
          </View>
        </Card.Content>
      </Card>

      <Card style={{ marginTop: 20 }}>
        <Tabs
          value={tab || ''}
          onChange={(_, newValue) =>
            history.push(`/tracks/${trackId}/${newValue}`)
          }
        >
          <Tab value="workouts" label={`Workouts (${track.workoutsCount})`} />
          {showUserTab && (
            <Tab
              value="users"
              label={`Users (${usersCount || track.usersCount})`}
            />
          )}
          {!isSingleUser && <Tab value="details" label="Details" />}
          {currentUser.superadmin && <Tab value="import" label="Import" />}
        </Tabs>

        <Switch>
          <Route path={`/tracks/${trackId}`} exact>
            <Redirect to={{ pathname: `/tracks/${trackId}/workouts` }} />
          </Route>
          <Route path={`/tracks/${trackId}/details`}>
            {track.payoutAccess && (
              <ActionsContainer>
                <Button
                  compact
                  mode="outlined"
                  color={colors.membershipColor}
                  icon="bank-transfer-out"
                  loading={expressLoading}
                  onPress={() => {
                    createExpressSession({
                      variables: { trackId: track.id },
                    }).then(({ data }) => {
                      if (data) window.location.href = data.url;
                    });
                  }}
                >
                  Payouts
                </Button>
              </ActionsContainer>
            )}
            <VerticalTable>
              <Row>
                <ContentCell>
                  <Text style={styles.boldText}>Name</Text>
                </ContentCell>
                <HeaderCell>
                  <EditableText
                    placeholder="-"
                    multiline={false}
                    initialValue={track.name}
                    onValueChange={(name) => {
                      if (name !== track.name) {
                        updateTrack({
                          variables: { track: { id: trackId, name } },
                        });
                      }
                    }}
                  />
                </HeaderCell>
              </Row>
              <Row>
                <ContentCell>
                  <Text style={styles.boldText}>Public Description</Text>
                </ContentCell>
                <HeaderCell>
                  <EditableText
                    placeholder="-"
                    initialValue={track.description}
                    onValueChange={(description) => {
                      if (description !== track.description) {
                        updateTrack({
                          variables: { track: { id: trackId, description } },
                        });
                      }
                    }}
                  />
                </HeaderCell>
              </Row>
              {!!track.compStatus && (
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>
                      Registration Description (*Markdown syntax)
                    </Text>
                  </ContentCell>
                  <HeaderCell>
                    <EditableText
                      placeholder="-"
                      initialValue={track.registrationDescription}
                      onValueChange={(registrationDescription) => {
                        if (
                          registrationDescription !==
                          track.registrationDescription
                        ) {
                          updateTrack({
                            variables: {
                              track: { id: trackId, registrationDescription },
                            },
                          });
                        }
                      }}
                    />
                  </HeaderCell>
                </Row>
              )}
              <Row>
                <ContentCell>
                  <Text style={styles.boldText}>Private Description</Text>
                </ContentCell>
                <HeaderCell>
                  <EditableText
                    placeholder="-"
                    initialValue={track.membershipDescription}
                    onValueChange={(membershipDescription) => {
                      if (
                        membershipDescription !== track.membershipDescription
                      ) {
                        updateTrack({
                          variables: {
                            track: { id: trackId, membershipDescription },
                          },
                        });
                      }
                    }}
                  />
                </HeaderCell>
              </Row>
              {trialPrice && (
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Trial Description</Text>
                  </ContentCell>
                  <HeaderCell>
                    <EditableText
                      placeholder="-"
                      initialValue={track.trialDescription}
                      onValueChange={(trialDescription) => {
                        if (trialDescription !== track.trialDescription) {
                          updateTrack({
                            variables: {
                              track: { id: trackId, trialDescription },
                            },
                          });
                        }
                      }}
                    />
                  </HeaderCell>
                </Row>
              )}
              {trialPrice && currentUser.superadmin && (
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Promo Message</Text>
                  </ContentCell>
                  <HeaderCell>
                    <EditableText
                      placeholder="-"
                      initialValue={track.promoMessage}
                      onValueChange={(promoMessage) => {
                        if (promoMessage !== track.promoMessage) {
                          updateTrack({
                            variables: {
                              track: { id: trackId, promoMessage },
                            },
                          });
                        }
                      }}
                    />
                  </HeaderCell>
                </Row>
              )}

              {!track.planWeeks && (
                <>
                  <Row>
                    <ContentCell>
                      <Text style={styles.boldText}>
                        Visible on Search/Discover
                      </Text>
                    </ContentCell>
                    <HeaderCell>
                      <FormControlLabel
                        style={{ marginRight: 10 }}
                        control={
                          <MuiSwitch
                            checked={track.discoverable}
                            onChange={(_e, checked) => {
                              updateTrack({
                                variables: {
                                  track: { id: trackId, discoverable: checked },
                                },
                              });
                            }}
                          />
                        }
                        label={track.discoverable ? 'Visible' : 'Hidden'}
                      />
                    </HeaderCell>
                  </Row>
                  {!activePrice && (
                    <Row>
                      <ContentCell>
                        <Text style={styles.boldText}>
                          Requires approval to join
                        </Text>
                      </ContentCell>
                      <HeaderCell>
                        <FormControlLabel
                          style={{ marginRight: 10 }}
                          control={
                            <MuiSwitch
                              checked={!track.autoJoin}
                              onChange={(_e, checked) => {
                                updateTrack({
                                  variables: {
                                    track: { id: trackId, autoJoin: !checked },
                                  },
                                });
                              }}
                            />
                          }
                          label={!track.autoJoin ? 'Yes' : 'No'}
                        />
                      </HeaderCell>
                    </Row>
                  )}
                </>
              )}

              {!isSingleUser && !track.planWeeks && (
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Workout Stats</Text>
                  </ContentCell>
                  <HeaderCell>
                    <FormControlLabel
                      style={{ marginRight: 10 }}
                      control={
                        <MuiSwitch
                          checked={track.workoutStats}
                          onChange={(_e, checked) => {
                            updateTrack({
                              variables: {
                                track: { id: trackId, workoutStats: checked },
                              },
                            });
                          }}
                        />
                      }
                      label={track.workoutStats ? 'Enabled' : 'Disabled'}
                    />
                  </HeaderCell>
                </Row>
              )}
              {!!track.compStatus && (
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Comp Status</Text>
                  </ContentCell>
                  <HeaderCell>
                    <Text> {track.compStatus}</Text>
                  </HeaderCell>
                </Row>
              )}
              {!!track.webPath && (
                <Row>
                  <ContentCell>
                    <View>
                      <Text style={styles.boldText}>Public Link</Text>
                      {moment(track.insertedAt).isAfter(
                        moment().add(-7, 'days')
                      ) && (
                        <Caption>
                          URL might not be available yet.{'\n'}Contact{' '}
                          <Link to="mailto:support@erg.zone?subject=Update Logo">
                            support@erg.zone
                          </Link>{' '}
                          for help{'\n'}or use the Temporary Link below.
                        </Caption>
                      )}
                    </View>
                  </ContentCell>
                  <HeaderCell>
                    <Link to={track.webPath}>
                      {track.webPath.replace(/https?:\/\//, '')}
                    </Link>
                  </HeaderCell>
                </Row>
              )}
              {track.prices.length > 0 && (
                <Row>
                  <ContentCell>
                    <View>
                      <Text style={styles.boldText}>Temporary Link</Text>
                      <Caption>Only valid for 7 days</Caption>
                    </View>
                  </ContentCell>
                  <HeaderCell>
                    <View>
                      {track.prices.map((p) => (
                        <Link
                          key={p.token}
                          to={`https://www.erg.zone/track?id=${p.token}`}
                        >
                          {p.name} - {p.displayText}
                        </Link>
                      ))}
                    </View>
                  </HeaderCell>
                </Row>
              )}

              {currentUser.superadmin && (
                <>
                  <Row>
                    <ContentCell>
                      <Text style={styles.boldText}>Mode</Text>
                    </ContentCell>
                    <HeaderCell>
                      <Text>{track.trackMode}</Text>
                    </HeaderCell>
                  </Row>
                  <Row>
                    <ContentCell>
                      <Text style={styles.boldText}>Discoverable</Text>
                    </ContentCell>
                    <HeaderCell>
                      <Text>{String(track.discoverable)}</Text>
                    </HeaderCell>
                  </Row>
                </>
              )}

              {track.planWeeks && (
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Plan Weeks</Text>
                  </ContentCell>
                  <HeaderCell>
                    <Text>{String(track.planWeeks)}</Text>
                  </HeaderCell>
                </Row>
              )}

              <Row>
                <ContentCell>
                  <Text style={styles.boldText}>Features</Text>
                </ContentCell>
                <HeaderCell>
                  <TouchableOpacity
                    style={{ flexDirection: 'row' }}
                    activeOpacity={currentUser.superadmin ? 0.8 : 1}
                    onPress={() => {
                      if (currentUser.superadmin) {
                        setShowFeaturesSelection(true);
                      }
                    }}
                  >
                    {track.features.length === 0 && <Text>-</Text>}
                    {track.features.map((feature) => (
                      <Tooltip title={capitalize(feature)} key={feature}>
                        <span>
                          <FeatureIcon
                            feature={feature}
                            {...iconProps}
                            style={{ margin: 4 }}
                          />
                        </span>
                      </Tooltip>
                    ))}
                  </TouchableOpacity>
                </HeaderCell>
              </Row>
              <Row>
                <ContentCell>
                  <Text style={styles.boldText}>User name display</Text>
                </ContentCell>
                <HeaderCell>
                  <Select
                    variant="outlined"
                    value={track.userDisplay}
                    onChange={(event) =>
                      updateTrack({
                        variables: {
                          track: {
                            id: trackId,
                            userDisplay: event.target.value,
                          },
                        },
                      })
                    }
                  >
                    <MenuItem value={UserDisplay.FirstName}>
                      First name Last name
                    </MenuItem>
                    <MenuItem value={UserDisplay.LastName}>
                      Last name, First name
                    </MenuItem>
                  </Select>
                </HeaderCell>
              </Row>
              <Row>
                <ContentCell>
                  <Text style={styles.boldText}>Logo</Text>
                </ContentCell>
                <HeaderCell>
                  <View style={{ alignItems: 'center', marginRight: 10 }}>
                    <Image
                      source={{ uri: track.imageUrl }}
                      style={{ width: 100, height: 100 }}
                    />
                    {showLogoUpload && (
                      <ImageUploadModal
                        showCircle
                        title="Logo Upload"
                        path={`source/${track.source.slug}/${track.slug}`}
                        width={200}
                        height={200}
                        onDismiss={() => setShowLogoUpload(false)}
                        onUpload={(path) => {
                          updateTrack({
                            variables: {
                              track: { id: trackId, imageUrl: path },
                            },
                          });
                          setShowLogoUpload(false);
                        }}
                      />
                    )}
                    <Button onPress={() => setShowLogoUpload(true)}>
                      Upload logo
                    </Button>
                  </View>
                  <View style={{ marginRight: 10 }}>
                    <Image
                      source={{ uri: track.featuredImage || track.imageUrl }}
                      style={{ width: 300, height: 100 }}
                    />
                    {showBannerUpload && (
                      <ImageUploadModal
                        showCircle={false}
                        title="Banner Upload"
                        path={`source/${track.source.slug}/${track.slug}`}
                        width={600}
                        height={200}
                        onDismiss={() => setShowBannerUpload(false)}
                        onUpload={(path) => {
                          updateTrack({
                            variables: {
                              track: { id: trackId, featuredImage: path },
                            },
                          });
                          setShowBannerUpload(false);
                        }}
                      />
                    )}
                    <Button onPress={() => setShowBannerUpload(true)}>
                      Upload banner
                    </Button>
                  </View>
                </HeaderCell>
              </Row>
              <Row>
                <ContentCell>
                  <Text style={styles.boldText}>Actions</Text>
                </ContentCell>
                <HeaderCell>
                  <Button
                    style={{ marginRight: 10 }}
                    icon="archive"
                    mode="outlined"
                    color={colors.primaryColor}
                    onPress={() => {
                      toggleArchiveMutation({
                        variables: { id: trackId },
                      }).then(({ data }) => {
                        if (data) {
                          showSnackBar({
                            message: `Track ${
                              data.trackArchiveToggle.archivedAt
                                ? 'archived'
                                : 'unarchived'
                            }`,
                          });
                        }
                      });
                    }}
                  >
                    {track.archivedAt ? 'Unarchive' : 'Archive'}
                  </Button>
                </HeaderCell>
              </Row>
            </VerticalTable>
          </Route>
          {showUserTab && (
            <Route path={`/tracks/${trackId}/users`}>
              <UsersTable
                trackId={trackId}
                onUsersCount={setUsersCount}
                userDisplay={track.userDisplay}
                isPlan={!!track.planWeeks}
              />
            </Route>
          )}
          <Route path={`/tracks/${trackId}/workouts`}>
            <WorkoutsTable
              key={trackId}
              trackId={trackId}
              isPlan={!!track.planWeeks}
            />
          </Route>
          {currentUser.superadmin && (
            <Route path={`/tracks/${trackId}/import`}>
              <CsvResultsImporter trackId={trackId} />
              <CsvErgWodImporter trackId={trackId} />
            </Route>
          )}
        </Switch>
      </Card>
      {showFeaturesSelection && (
        <FeaturesSelection
          currentUser={currentUser}
          features={track.features}
          setFeatures={(features) => {
            updateTrack({
              variables: {
                track: { id: trackId, features },
              },
            });
          }}
          onDismiss={() => setShowFeaturesSelection(false)}
        />
      )}
    </View>
  );
};

export default TrackShow;
