import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { sortBy } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  CURRENT_USER_FRAGMENT,
  TRACK_FRAGMENT,
} from 'shared/graphql/fragments';
import { AdminTrackAction, CurrentUser, Track, TrackMode } from 'shared/types';
import { hasMobileState, isMobileApp } from 'utils/mobile';
import { removeSessionToken } from 'utils/session';

export interface AdminTrack extends Track {
  archivedAt?: string;
}
export interface AdminCurrentUser extends CurrentUser {
  email: string;
  insertedAt: string;
}

interface CurrentUserContextType {
  currentUser: AdminCurrentUser;
  adminTracks: AdminTrack[];
  hasAdminAccess: boolean;
  logout(): void;
  reload(): void;
}

const CurrentUserContext = createContext<CurrentUserContextType>({
  // @ts-ignore
  currentUser: {},
  adminTracks: [],
  hasAdminAccess: true,
});

const GET_USER = gql`
  query GetUser {
    tracks {
      ...TrackFragment
      archivedAt
      labels {
        id
        name
        type
      }
      labelTypes
    }
    currentUser {
      superadmin
      email
      insertedAt
      ...CurrentUserFragment
    }
  }
  ${CURRENT_USER_FRAGMENT}
  ${TRACK_FRAGMENT}
`;

const useMobileAppLoader = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isMobileApp()) {
      const timer = setInterval(() => {
        if (hasMobileState()) {
          setLoaded(true);
          clearInterval(timer);
        }
      }, 200);
    } else {
      setLoaded(true);
    }
  }, []);

  return loaded;
};

export function CurrentUserProvider(props: any) {
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [adminTracks, setAdminTracks] = useState<Track[]>([]);
  const [hasAdminAccess, setHasAdminAccess] = useState(true);

  const mobileAppLoaded = useMobileAppLoader();

  const { pathname } = window.location;
  const skipQuery =
    pathname.startsWith('/auth/') || pathname.startsWith('/login');

  const { refetch, data } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    skip: skipQuery || !mobileAppLoaded,
  });

  useEffect(() => {
    if (data) {
      setCurrentUser(data.currentUser);
      const tracks = sortBy(
        data.tracks.filter((t: Track) => t.role === 'admin'),
        (track) => [
          track.trackMode === TrackMode.Tiered ? 0 : 1,
          track.trackMode === TrackMode.General ? 0 : 1,
        ]
      ) as AdminTrack[];
      setAdminTracks(tracks);
      setHasAdminAccess(
        tracks.some((t) => t.adminActions.includes(AdminTrackAction.Edit))
      );
    }
  }, [data]);

  const logout = useCallback(() => {
    removeSessionToken();
    window.location.href = '/login';
  }, []);

  const reload = useCallback(() => {
    refetch();
  }, [refetch]);

  const value = {
    currentUser,
    adminTracks,
    hasAdminAccess,
    reload,
    logout,
  };

  return (
    <CurrentUserContext.Provider
      value={value}
      {...props}
      children={currentUser || skipQuery ? props.children : null}
    />
  );
}

const useCurrentUser = () => {
  const currentUserContext = useContext(
    CurrentUserContext
  ) as CurrentUserContextType;

  if (
    !isMobileApp() &&
    !currentUserContext.hasAdminAccess &&
    !window.location.pathname.startsWith('/billing')
  ) {
    window.location.href = '/billing';
  }

  return currentUserContext;
};

export default useCurrentUser;
