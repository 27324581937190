import { useQuery } from '@apollo/react-hooks';
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import {
  Theme,
  createMuiTheme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { View } from 'react-native';
import { ProgressBar } from 'react-native-paper';
import { useParams } from 'react-router-dom';
import DateRangeModal, {
  customDateRangeDisplay,
  DateRange,
} from 'shared/components/DateRangeModal';
import ErgIcon from 'shared/components/ErgIcon';
import Logo from 'shared/components/Logo';
import { colors } from 'shared/styles';
import { ErgType, TrackRole } from 'shared/types';
import {
  calculateCalsHour,
  calculatePace,
  calculatePower,
  ergTypeDisplay,
  formatTime,
  humanizeDistance,
  statsEmojis,
  userColor,
} from 'shared/utils';
import { muiThemeAttributes } from 'utils/helpers';
import { isMobileApp, postMobileMessage } from 'utils/mobile';

const GET_STATS = gql`
  query GetTrackStats(
    $trackIds: [ID!]
    $trackId: ID!
    $time: String!
    $ergTypes: [String]
    $startDate: String
    $endDate: String
    $timezone: String
  ) {
    track(id: $trackId) {
      id
      role
      source {
        id
        name
        imageUrl
        insertedAt
      }
      tracksFromSource {
        id
        name
        imageUrl
      }
    }
    trackStats(
      ids: $trackIds
      time: $time
      ergTypes: $ergTypes
      startDate: $startDate
      endDate: $endDate
      timezone: $timezone
    ) {
      totalResults
      totalUsers
      totalTime
      totalDistance
      weightedDistance
      totalReactions
      userStats {
        name
        total
      }
    }
  }
`;

interface Params {
  userToken: string;
}

interface TrackStats {
  totalResults: number;
  totalUsers: number;
  totalTime: number;
  totalDistance: number;
  weightedDistance: number;
  totalReactions: number[];
  userStats: UserStat[];
}

interface UserStat {
  name: string;
  total: number[];
}

interface TrackData {
  id: string;
  role: TrackRole;
  source: { id: string; name: string; imageUrl: string; insertedAt: string };
  tracksFromSource: { id: string; name: string; imageUrl: string }[];
}

interface QueryData {
  track: TrackData;
  trackStats: TrackStats;
}

const muiTheme = createMuiTheme({
  ...muiThemeAttributes,
  palette: {
    ...muiThemeAttributes.palette,
    type: 'dark',
  },
});

const defaultErgs = [
  ErgType.row,
  ErgType.ski,
  ErgType.bike,
  ErgType.fanBike,
  ErgType.run,
  ErgType.multi,
];

const StatItem = ({
  name,
  children,
  style,
}: {
  name: string;
  children: ReactNode;
  style?: any;
}) => {
  // @ts-ignore
  const styles = useStyles();

  return (
    <div className={styles.statItemContainer} style={style}>
      <InputLabel className={styles.statItemHeader}>{name}</InputLabel>
      <Typography variant="h4" className={styles.statItemValue}>
        {children}
      </Typography>
    </div>
  );
};

const UserItem = ({ stat }: { stat: UserStat }) => {
  // @ts-ignore
  const styles = useStyles();
  const color = userColor(stat.name);

  return (
    <View
      style={{
        borderColor: color,
        borderWidth: 1,
        borderRadius: 10,
        paddingTop: 20,
        padding: 10,
        width: 330,
      }}
    >
      <div className={styles.userItemHeader}>
        <InputLabel className={styles.userItemHeaderName} style={{ color }}>
          {nameDisplay(stat.name)}
        </InputLabel>
      </div>
      <Typography
        variant="h4"
        className={styles.statItemValue}
        style={{ color }}
      >
        {displayUserDistances(stat.total, color)}
      </Typography>
    </View>
  );
};

const humanizeTime = (value: number) => {
  const duration = moment.duration(value * 1000);
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  const values = [];

  if (years) {
    values.push(`${years}y`);
    values.push(`${months}m`);
    values.push(`${days}d`);
  } else if (months) {
    values.push(`${months}m`);
    values.push(`${days}d`);
  } else if (days) {
    values.push(`${days}d`);
    if (hours) values.push(`${hours}h`);
  } else {
    if (hours) values.push(`${hours}h`);
    if (minutes) values.push(`${minutes}m`);
  }

  return values.join(' ');
};

const getYears = (insertedAt: string) => {
  const start = moment(insertedAt).year();
  const end = moment().year();
  return Array.from(Array(end - start + 1).keys())
    .map((i) => start + i)
    .reverse();
};

const EmptyIcon = () => null;

interface Params {
  trackId?: string;
}

const TrackStatsPage = () => {
  // @ts-ignore
  const styles = useStyles();
  const [time, setTime] = useState(isMobileApp() ? '7-day' : '1-day');
  const { trackId: trackIdFromParam } = useParams<Params>();
  const [trackIds, setTrackIds] = useState([trackIdFromParam]);
  const [ergTypes, setErgTypes] = useState(defaultErgs);
  const [customDateRange, setCustomDateRange] = useState<DateRange>();
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);

  const variables = {
    trackId: trackIdFromParam,
    trackIds,
    time,
    ...(time === 'custom' ? customDateRange : {}),
    ergTypes,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const onErgChange = (event: any) => {
    const erg = event.target.name;
    ergTypes.includes(erg)
      ? setErgTypes(ergTypes.filter((t) => t !== erg))
      : setErgTypes([...ergTypes, erg]);
  };

  useEffect(() => {
    // @ts-ignore
    document.body.style.backgroundColor = bgColor;

    setTimeout(() => postMobileMessage('loaded'), 100);
  }, []);

  const { data, loading } = useQuery<QueryData>(GET_STATS, {
    fetchPolicy: 'cache-and-network',
    variables,
    pollInterval: 10 * 60 * 1000,
  });

  const track = data && data.track;
  const trackStats = data ? data.trackStats : null;

  const stats = trackStats ? trackStats.userStats : [];
  const totalDistance = trackStats ? trackStats.totalDistance : 0;
  const totalResults = trackStats ? trackStats.totalResults : 0;
  const totalUsers = trackStats ? trackStats.totalUsers : 0;
  const totalTime = trackStats ? trackStats.totalTime : 0;
  const totalReactions = trackStats ? trackStats.totalReactions : [0, 0, 0, 0];
  const pace = trackStats
    ? calculatePace(
        trackStats.totalTime,
        trackStats.weightedDistance,
        ErgType.row
      )
    : 0;
  const avgCalories =
    trackStats && pace ? calculateCalsHour(calculatePower(pace)) : 0;

  const years = track ? getYears(track.source.insertedAt) : [];

  const showOlderTimeframes = !!track && track.role === TrackRole.Admin;

  const TrackItem = ({
    name,
    imageUrl,
  }: {
    name: string;
    imageUrl: string;
  }) => {
    return (
      <div className={styles.trackItemContainer}>
        <Avatar style={{ width: 40, height: 40 }} src={imageUrl} />
        <div className={styles.trackName}>{name}</div>
      </div>
    );
  };

  return (
    <ThemeProvider theme={muiTheme}>
      {loading && (
        <ProgressBar style={{ position: 'absolute', top: 0 }} indeterminate />
      )}
      <div className={styles.centerContainer}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            {!!track && !isMobileApp() && (
              <div className={styles.trackContainer}>
                <FormControl
                  variant="outlined"
                  className={styles.trackFormContainer}
                >
                  <Select
                    labelId="track-label"
                    value={trackIds.length === 1 ? trackIds[0] : 'all'}
                    IconComponent={EmptyIcon}
                    onChange={(event) => {
                      const value = event.target.value as string;
                      setTrackIds(
                        value === 'all'
                          ? track.tracksFromSource.map((t) => t.id)
                          : [value]
                      );
                    }}
                  >
                    {track.tracksFromSource.length > 1 && (
                      <MenuItem value="all">
                        <TrackItem
                          name={`${track.source.name}: All`}
                          imageUrl={track.source.imageUrl}
                        />
                      </MenuItem>
                    )}
                    {track.tracksFromSource.map((t) => (
                      <MenuItem key={t.id} value={t.id}>
                        <TrackItem name={t.name} imageUrl={t.imageUrl} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <div
              className={styles.filterContainer}
              style={isMobileApp() ? { maxWidth: 500 } : undefined}
            >
              {isMobileApp() && (
                <FormGroup
                  style={{
                    flex: 1,
                    marginBottom: 20,
                    flexDirection: 'row',
                  }}
                >
                  {defaultErgs.map((ergType) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ergTypes.includes(ergType)}
                          onChange={onErgChange}
                          name={ergType}
                        />
                      }
                      label={ergTypeDisplay(ergType)}
                    />
                  ))}
                </FormGroup>
              )}
              <FormControl variant="outlined" style={{ flex: 1 }}>
                <InputLabel id="timeframe-label">Timeframe</InputLabel>
                <Select
                  labelId="timeframe-label"
                  value={time}
                  onChange={(event) => {
                    if (event.target.value !== 'custom') {
                      setTime(event.target.value as string);
                    }
                  }}
                  label="Timeframe"
                >
                  <MenuItem value="1-hour">Past hour</MenuItem>
                  <MenuItem value="1-day">Past day</MenuItem>
                  <MenuItem value="7-day">Past 7 days</MenuItem>
                  <MenuItem value="30-day">Past 30 days</MenuItem>
                  {showOlderTimeframes && (
                    <MenuItem value="365-day">Past 365 days</MenuItem>
                  )}
                  {(showOlderTimeframes ? years : []).map((year) => (
                    <MenuItem key={year} value={`year-${year}`}>
                      {year}
                    </MenuItem>
                  ))}
                  {showOlderTimeframes && (
                    <MenuItem value="lifetime">Lifetime</MenuItem>
                  )}
                  {showOlderTimeframes && (
                    <MenuItem
                      value="custom"
                      onClick={() => setShowDateRangeModal(true)}
                    >
                      {customDateRangeDisplay(customDateRange)}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            {!isMobileApp() && (
              <div className={styles.poweredContainer}>
                <span style={{ marginRight: 4 }}>Powered by</span>
                <Logo size={24} darkMode />
              </div>
            )}
          </div>

          <div style={{ flex: 1 }}>
            <div className={styles.statItemsContainer}>
              <StatItem name="Total Results">
                {totalResults ? totalResults : '-'}
              </StatItem>
              <StatItem name="Total Athletes">
                {totalUsers ? totalUsers : '-'}
              </StatItem>
              <StatItem name="Total Distance">
                {totalDistance ? humanizeDistance(totalDistance) : '-'}
              </StatItem>
              <StatItem name="Total Time">
                {totalTime ? humanizeTime(totalTime) : '-'}
              </StatItem>

              <StatItem name="Average Pace">
                {pace ? formatTime(pace, true) : '-'}
              </StatItem>
              <StatItem name="Average Watts">
                {pace ? calculatePower(pace).toFixed(0) : '-'}
              </StatItem>
              <StatItem name="Average Cals/hr">
                {avgCalories
                  ? humanizeDistance(avgCalories, { meters: '' })
                  : '-'}
              </StatItem>
              <StatItem name="Total Reactions">
                <div className={styles.emojisContainer}>
                  {statsEmojis.map((emoji, index) => (
                    <div key={emoji} className={styles.emojiContainer}>
                      <div style={{ fontSize: isMobileApp() ? 14 : undefined }}>
                        {emoji}
                      </div>
                      <div
                        className={styles.emojiCount}
                        style={{ fontSize: isMobileApp() ? 14 : undefined }}
                      >
                        {totalReactions[index]}
                      </div>
                    </div>
                  ))}
                </div>
              </StatItem>
            </div>
            <div className={styles.metersLabel}>Meters</div>
            <div className={styles.usersContainer}>
              {stats.map((stat, i) => (
                <UserItem key={i} stat={stat}></UserItem>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showDateRangeModal && (
        <DateRangeModal
          minDate={moment().subtract(100, 'years')}
          maxDate={moment()}
          initialDateRange={
            customDateRange || {
              startDate: moment().subtract('7', 'days').format('YYYY-MM-DD'),
              endDate: moment().format('YYYY-MM-DD'),
            }
          }
          onDismiss={() => {
            setShowDateRangeModal(false);
          }}
          onComplete={(range) => {
            setTime('custom');
            setShowDateRangeModal(false);
            setCustomDateRange(range);
          }}
        />
      )}
    </ThemeProvider>
  );
};

const nameDisplay = (name: string) => {
  const [first, last] = name.split(' ');
  return `${first} ${last ? last[0] : ''}`;
};

const displayUserDistances = (distances: number[], color: string) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      {distances.map((distance, index) => {
        return distance ? (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontSize: '0.66em',
            }}
          >
            <ErgIcon ergType={defaultErgs[index]} size={20} color={color} />
            {humanizeDistance(distance)}
          </div>
        ) : null;
      })}
    </div>
  );
};

const bgColor = colors.darkBackground;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerContainer: {
      width: '100%',
      background: bgColor,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      color: 'white',
    },
    trackContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignSelf: 'stretch',
      },
    },
    trackFormContainer: {
      [theme.breakpoints.down('sm')]: {
        flex: 1,
      },
    },
    trackName: {
      color: 'white',
      ...theme.typography.h5,
      fontWeight: 'bold',
      marginLeft: theme.spacing(1),
    },
    trackItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    metersLabel: {
      color: 'white',
      ...theme.typography.h4,
      marginBottom: theme.spacing(3),
    },
    headerContainer: {
      width: 'auto',
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'justify-center',
        gap: '20px',
      },
    },
    filterContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      maxWidth: 400,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        maxWidth: '100%',
        alignSelf: 'stretch',
      },
    },
    poweredContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'flex-end',
      },
    },
    userItemHeader: {
      position: 'absolute',
      top: -12,
      left: theme.spacing(1),
      right: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      alignItemsCenter: 'center',
    },
    userItemHeaderName: {
      textAlign: 'center',
      backgroundColor: bgColor,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      fontSize: 24,
      paddingLeft: 4,
      paddingRight: 4,
    },
    statItemsContainer: {
      display: 'grid',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
      },
      gridTemplateColumns: 'repeat(4,minmax(0,1fr))',
      marginBottom: theme.spacing(4),
      gap: '1.2rem',
    },
    statItemContainer: {
      position: 'relative',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      border: '1px solid rgba(255,255,255, 0.3)',
      borderRadius: theme.shape.borderRadius,
    },
    statItemHeader: {
      position: 'absolute',
      top: -10,
      left: theme.spacing(1),
      paddingLeft: 2,
      paddingRight: 2,
      backgroundColor: bgColor,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      fontSize: 20,
    },
    statItemValue: {
      fontWeight: 800,
    },
    emojisContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    emojiContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    emojiCount: {
      marginLeft: 4,
      marginRight: 16,
      fontSize: '0.86em',
    },
    usersContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '1.25rem',
      justifyContent: 'space-evenly',
    },
  })
);

export default TrackStatsPage;
